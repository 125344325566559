const normalizeKey = (key) => {
    const normalizedKey = key
        .replace(/^(game-popup-|game-)/, '')
        .replace(/-([a-z])/g, (_, letter) => letter.toUpperCase());

    return normalizedKey.charAt(0).toUpperCase() + normalizedKey.slice(1);
};

/**
 * Filter an input object for keys starting with "game-", convert those keys from kebab-case to PascalCase, and return a new object with the converted keys and their corresponding values
 *
 * @param apiConfig - dynamic config object from sharpstar api getOffers
 *
 * return {[key]: value}
 */
export const getGameInitValues = (apiConfig) => {
    return Object.entries(apiConfig).reduce((result, [key, value]) => {
        if (key.startsWith('game-popup-')) {
            const normalizedPopupKey = normalizeKey(key);

            result['popup'] = {
                ...result['popup'],
                [normalizedPopupKey]: value,
            };
        } else if (key.startsWith('game-')) {
            const normalizedKey = normalizeKey(key);

            result[normalizedKey] = value;
        }
        return result;
    }, {});
};

/**
 * Filters game related "key: value" pairs from sharpstar config
 *
 * return {[string]: string}
 */
export const filterGameRelatedAttributes = (inputObj) => {
    const outputObj = {};

    for (const key in inputObj) {
        if (
            (key.startsWith('game-') || key.startsWith('build-')) &&
            !key.includes('addressables')
        ) {
            outputObj[key] = inputObj[key];
        }
    }

    return outputObj;
};

import * as Sentry from '@sentry/browser';
import SharpstarCallFailedError from '@/Services/Errors/SharpstarCallFailedError';
import BadRequestError from '@/Services/Handlers/Errors/BadRequestError';
import SharpStarDataService from '@/Services/SharpStarDataService';
import UrlParams from '@/Helpers/UrlParams';

export default class SharpstarServiceHandler {
    /**
     * initialize sharpstar srevice handler by page setup
     * @param sharpstarBaseUrl
     * @param source
     * @param campaignId
     * @param abortController
     * @param cookieService
     */
    constructor(
        sharpstarBaseUrl,
        source,
        campaignId,
        cookieService,
        abortController
    ) {
        this.SharpStarDataService = new SharpStarDataService(
            sharpstarBaseUrl,
            source,
            campaignId,
            abortController
        );
        this.cookieService = cookieService;
    }

    /**
     * call sharpstar getOffers, sort offers and return as Promise
     * @param hash
     * @param isLead
     * @param rid
     * @returns {Promise<any>}
     */
    async getOffers(hash, isLead, rid = UrlParams.rid) {
        const response = await this.SharpStarDataService.getOffers(
            hash,
            isLead,
            rid
        );
        const resBody = await response.json();

        if (!response.ok) {
            if (resBody.status === 'error') {
                throw new SharpstarCallFailedError(
                    resBody.message,
                    resBody.error_code
                );
            } else if (resBody === 'Bad Request') {
                throw new BadRequestError();
            } else {
                throw new Error(
                    `Failed with response not ok. Response: ${response.status || 'no status'} ${response.message || 'something went wrong'} ${response.statusText}`
                );
            }
        } else {
            if (resBody.status === 'success') {
                this.sortOffers(resBody);

                return resBody;
            } else {
                throw new Error(resBody);
            }
        }
    }

    /**
     * call sharpstar add device info when OneSignal push lead was created and return response body.
     * @param device - string (required)
     * @param browser - string (required)
     * @param uuid - string (required)
     */
    async addOneSignalLeadCountTest(device, browser, uuid) {
        const response =
            await this.SharpStarDataService.addOneSignalLeadCountTest(
                device,
                browser,
                uuid
            );
        const resBody = await response.json();

        if (!response.ok) {
            Sentry.captureException(
                new Error('Send OneSignal lead discrepancy info failed')
            );
        } else {
            return resBody;
        }
    }

    /**
     * call sharpstar addExtraData function to post additional app data
     * @param hash - string (required)
     * @param extraData - object (required) - any number of key:value pairs
     */
    async addExtraData(hash, extraData) {
        const response = await this.SharpStarDataService.addExtraData(
            hash,
            extraData
        );
        const resBody = await response.json();

        if (!response.ok) {
            if (resBody.status === 'error') {
                throw new SharpstarCallFailedError(
                    resBody.message,
                    resBody.error_code
                );
            } else if (resBody === 'Bad Request') {
                throw new BadRequestError();
            } else {
                throw new Error(
                    `Response: ${response.status || 'no status'} ${response.message || 'no error message'} ${response.statusText}`
                );
            }
        } else {
            return resBody;
        }
    }

    /**
     * call sharpstar creat lead, sort offers, set cookie and return as Promise.
     * @param email
     * @param name
     * @param offerId
     * @param extraData
     * @returns {Promise<*>}
     */
    async createLead(email, name, offerId, extraData) {
        const creatLead = await this.SharpStarDataService.createLead(
            email,
            name,
            offerId,
            extraData
        );
        return this.handleRequest(creatLead, 'creat lead');
    }

    /**
     * call sharpstar creat push lead, sort offers, set cookie and return as Promise.
     * @param device_id
     * @param offerId
     * @param extraData
     * @param rid
     * @returns {Promise<*>}
     */
    async createPushLead(device_id, offerId, rid = UrlParams.rid, extraData) {
        const createPushLead = await this.SharpStarDataService.createPushLead(
            device_id,
            offerId,
            rid,
            extraData
        );
        return this.handleRequest(createPushLead, 'creat push lead');
    }

    /**
     * call sharpstar add push device, sort offers, set cookie and return as Promise.
     * @param device_id
     * @param hash
     * @param offerId
     * @returns {Promise<*>}
     */
    async addPushDevice(device_id, hash, offerId) {
        const addPushDevice = await this.SharpStarDataService.addPushDevice(
            device_id,
            hash,
            offerId
        );
        return this.handleRequest(addPushDevice, 'add push device');
    }

    /**
     * call sharpstar add contact info, sort offers, set cookie and return as Promise.
     * @param name
     * @param email
     * @param hash
     * @param offerId
     * @param extraData
     * @returns {Promise<*>}
     */
    async addContactInfo(name, email, hash, offerId, extraData) {
        const addContactInfo = await this.SharpStarDataService.addContactInfo(
            name,
            email,
            hash,
            offerId,
            extraData
        );
        return this.handleRequest(addContactInfo, 'add contact info');
    }

    /**
     * sort the response from sharpstar by rating, set cookie and add breadcrumb if fails, return the sorted response.
     * @param req
     * @param requestType
     * @returns {Promise<any>}
     */
    async handleRequest(req, requestType) {
        const res = await req.json();

        if (res.status === 'success') {
            this.sortOffers(res);
            localStorage.setItem('hash', res.hash);
            this.cookieService.setCookie(
                res.hash,
                res.lead.esp_status,
                res.lead.push_status,
                this.SharpStarDataService.campaignId
            );
        } else if (res.status === 'error') {
            throw new SharpstarCallFailedError(res.message, res.error_code);
        } else if (res === 'Bad Request') {
            throw new BadRequestError();
        }
        return res;
    }

    /**
     * sort response from sharpstar by rating. if there is no rating return false(undefined > undefined => false).
     * @param rule
     * @returns {*}
     */
    sortOffers(rule) {
        return rule.offers.sort((a, b) => (a.rating > b.rating ? 1 : -1));
    }
}

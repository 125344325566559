import React from 'react';
import * as Sentry from '@sentry/browser';
import {
    browserName,
    deviceDetect,
    fullBrowserVersion,
    osName,
    osVersion,
} from 'react-device-detect';
import UrlParams from 'App/Helpers/UrlParams';
import SharpstarServiceHandler from 'App/Services/Handlers/SharpstarServiceHandler';
import config from './config';
import CookieService from 'App/Services/CookieService';
import WebsiteDataService from 'App/Services/WebsiteDataService';
import SentryConfig from 'App/Helpers/SentryConfig';
import { environment, isProd } from 'App/Helpers/enviroments';
import GoogleAnalytics from 'App/Services/Analytics/GA4';
import GameAnalyticsService from 'App/Services/Analytics/GameAnalytics';
import checkAdBlockers from 'App/Helpers/checkAdBlockers';
import {
    filterGameRelatedAttributes,
    getGameInitValues,
} from 'App/Game/helpers';
import { getHash } from 'App/helpers';
import { isPwaInstallable } from './providers';
import { getGameDefaults } from './helpers';
import Landing from './components/Landing';
import GameContainer from './components/Game/components/GameContainer';
import PushPhase from './components/PushPhase';
import AdBlockerModal from './components/AdBlockerModal';
import InstallPhase from './components/InstallPhase';
import './main.scss';

const phaseMap = {
    1: 'landing',
    2: 'push',
    3: 'pwa',
    4: 'game',
};

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            phase: '', // 'landing','push','pwa','game'
            offerId: null,
            adBlocker: false,
            showAdBlockerModal: false,
            ga: null, // Google Analytics
            gameGA: null, // Game Analytics
            adProvider: null,
            preroll: {
                showInterval: 1,
                showStartAttempt: 2,
            },
            gameConfig: {
                buildVersion: null,
                buildUrl: null,
            },
        };

        this.setUrlParam = this.setUrlParam.bind(this);
        this.setPhase = this.setPhase.bind(this);
        this.updateState = this.updateState.bind(this);
        this.createPushLead = this.createPushLead.bind(this);
        this.updateLead = this.updateLead.bind(this);
        this.resolveAdsBlocked = this.resolveAdsBlocked.bind(this);

        this.setUrlParamValues(); // Set refids, hash, rid
        this.initServices(); // Init SharpStar, Sentry, WebsiteDataService
    }

    setUrlParam(name) {
        if (
            this[name] !== '0' &&
            this[name] !== null &&
            this[name] !== undefined
        ) {
            localStorage.setItem(name, this[name]); // If exists save it to local storage.
        } else if (localStorage.getItem(name)) {
            this[name] = localStorage.getItem(name); // Else get from local storage and set
        }
    }

    setUrlParamValues() {
        this.refid3 = UrlParams.refid3;
        this.refid2 = UrlParams.refid2;
        this.refid1 = UrlParams.refid1;
        this.hash = UrlParams.hash;
        this.rid = UrlParams.rid || config.rid;

        this.setUrlParam('refid3');
        this.setUrlParam('refid2');
        this.setUrlParam('refid1');
        this.setUrlParam('hash');
        this.setUrlParam('rid');
    }

    initServices() {
        this.sharpstarDataService = new SharpstarServiceHandler(
            config.sharpstarApiUrl,
            config.source,
            config.campaignId,
            new CookieService(config.domain)
        );

        this.websiteDataService = new WebsiteDataService(
            config.modalsUrl,
            config.lang
        );

        SentryConfig.init(
            config.sentryEnabled,
            null,
            config.source,
            config.campaignId,
            null,
            document.location.host,
            environment(),
            this.refid3
        );
    }

    async componentDidMount() {
        this.checkPhase();

        if ('serviceWorker' in navigator) {
            navigator.serviceWorker
                .getRegistrations()
                .then(function (registrations) {
                    for (let registration of registrations) {
                        if (
                            registration.active.scriptURL.includes(
                                'service-worker.js'
                            )
                        ) {
                            registration.unregister();
                        }
                    }
                });
        }

        const { pushProvider, pwaProvider } = this.props;

        this.sharpstarDataService
            .getOffers(undefined, 0, this.rid)
            .then((response) => {
                const { id, content } = response.offers[0];
                const adProvider = localStorage.getItem('adProvider');
                const { popup, ...rest } = getGameInitValues(content);

                pushProvider.setPushPromptTotal(+content['push-prompt-total']);
                pwaProvider.setPwaPhaseTotal(+content['pwa-prompt-total']);

                this.setState({
                    offerId: id,
                    adProvider: adProvider || content['ad-provider'],
                    gameConfig: {
                        buildVersion: content['build-version'],
                        buildUrl: `${config.buildPath}${content['build-version']}/`,
                        main: { ...rest },
                        popup: popup,
                    },
                    preroll: {
                        showInterval: +content['show-preroll-attempt-interval'],
                        showStartAttempt:
                            +content['show-preroll-start-attempt'],
                    },
                    leadExtraData: filterGameRelatedAttributes(content),
                });

                localStorage.setItem('offerId', id);
                localStorage.setItem('buildVersion', content['build-version']);
                !adProvider &&
                    localStorage.setItem('adProvider', content['ad-provider']);
            })
            .catch((e) => {
                const defaults = getGameDefaults(config);

                pushProvider.setPushPromptTotal(0);
                pwaProvider.setPwaPhaseTotal(0);
                this.setState({ ...defaults });
                Sentry.captureException(e);
            })
            .finally(() => {
                checkAdBlockers((adsBlocked) =>
                    this.resolveAdsBlocked(adsBlocked)
                );
            });

        window.addEventListener('hashchange', () => {
            const urlHashValue = getHash();

            if (phaseMap[urlHashValue]) {
                this.setPhase(phaseMap[urlHashValue]);
            }
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.gameGA !== prevState.gameGA) {
            const { gameGA, ga } = this.state;

            this.updateAnalyticsWithDeviceInfo(gameGA, ga);
            this.updateAnalyticsWithPwaSupport(gameGA, ga);
            this.updateAnalyticsWithPushSupport(
                this.props.pushProvider.pushViews,
                gameGA,
                ga
            );
        }
        if (
            prevState.gameConfig.buildVersion !==
            this.state.gameConfig.buildVersion
        ) {
            Sentry.addBreadcrumb({
                message: this.state.gameConfig.buildVersion.replace(/\./g, '-'),
                level: 'info',
                category: 'Game Build',
            });
        }
    }

    updateState(value) {
        this.setState(value);
    }

    getPhase() {
        return localStorage.getItem('phase') || 'landing';
    }

    setPhase(name) {
        this.setState({ phase: name });
        localStorage.setItem('phase', name);
    }

    checkPhase() {
        const urlHashValue = getHash();

        if (phaseMap[urlHashValue]) {
            this.setPhase(phaseMap[urlHashValue]);
        } else {
            const phase = this.getPhase();
            const newPhase =
                phase === 'landing'
                    ? 'landing'
                    : localStorage.getItem('showPushPhase') === 'true'
                      ? 'push'
                      : localStorage.getItem('showPwaPhase') === 'true'
                        ? 'pwa'
                        : 'game';

            this.setPhase(newPhase);
        }
    }

    resolveAdsBlocked(adsBlocked) {
        const { phase, gameConfig } = this.state;

        if (adsBlocked) {
            phase !== 'landing' && this.setState({ showAdBlockerModal: true });
            this.setState({ adBlocker: adsBlocked });
        } else {
            this.setState({
                ga: new GoogleAnalytics(
                    true,
                    config.source,
                    config.gaTrackId,
                    {},
                    { debug_mode: environment() !== 'production' }
                ),
            });
            this.setState({
                gameGA: new GameAnalyticsService(
                    config.gameAnalyticsKey,
                    config.gameAnalyticsSecret,
                    environment(),
                    gameConfig.buildVersion
                ),
            });
        }
    }

    updateAnalyticsWithPwaSupport(gameGA, ga) {
        if (!isPwaInstallable()) {
            gameGA.sendDesignEvent('Pwa:NotSupported');
            ga.basicGaEvent('pwa', 'not_supported');
            return;
        }

        if (localStorage.getItem('pwa')) {
            if (window.matchMedia('(display-mode: standalone)').matches) {
                gameGA.sendDesignEvent('Pwa:OpenedStandalone');
                ga.basicGaEvent('pwa', 'opened_standalone');
            }
            if (window.matchMedia('(display-mode: browser)').matches) {
                gameGA.sendDesignEvent('Pwa:OpenedBrowser');
                ga.basicGaEvent('pwa', 'opened_browser');
            }
        }
    }

    updateAnalyticsWithPushSupport(pushPhaseViews, gameGA, ga) {
        if (!('Notification' in window)) {
            // push notification is not available for current browser
            gameGA.sendDesignEvent('Push:NotAvailable');
            ga.basicGaEvent('push', 'not_available');
        } else if (pushPhaseViews === 0) {
            if (Notification?.permission === 'granted') {
                // user has permitted web push for all web pages
                gameGA.sendDesignEvent('Push:GrantedByBrowser');
                ga.basicGaEvent('push', 'granted_by_browser');
            }
            if (Notification?.permission === 'denied') {
                // user has forbidden web push for all web pages
                gameGA.sendDesignEvent('Push:DeniedByBrowser');
                ga.basicGaEvent('push', 'denied_by_browser');
            }
        }
    }

    updateAnalyticsWithDeviceInfo = (gameGA, ga) => {
        const deviceInfo = deviceDetect(undefined);
        const deviceType = deviceInfo.vendor ? 'Mobile' : 'Desktop';

        gameGA?.sendDesignEvent(
            'HP:DeviceInfo',
            1,
            JSON.stringify(
                deviceInfo.vendor
                    ? { vendor: deviceInfo.vendor }
                    : { browserName: deviceInfo.browserName }
            )
        );
        gameGA?.sendDesignEvent(
            `Device:${deviceType}:${deviceType === 'Mobile' ? deviceInfo.vendor : deviceInfo.browserName}:${deviceType === 'Mobile' ? deviceInfo.model : deviceInfo.browserFullVersion}`
        );
        gameGA?.sendDesignEvent(
            `Browser:${browserName}:${fullBrowserVersion}:${deviceType}`
        );
        ga?.gaEvent('device_info', deviceInfo);
    };

    createPushLead(oneSignalId) {
        try {
            const { offerId, leadExtraData } = this.state;
            const offer = offerId || localStorage.getItem('offerId');
            const extraData = {};

            if (localStorage.getItem('pwa') === 'Installed') {
                extraData['pwa_install'] = 'true';
            }

            Object.assign(extraData, leadExtraData);
            this.sharpstarDataService.createPushLead(
                oneSignalId,
                offer,
                this.rid,
                extraData
            );
        } catch (e) {
            Sentry.captureException(e);
        }

        this.sharpstarDataService.addOneSignalLeadCountTest(
            `${osName} ${osVersion}`,
            `${browserName} ${fullBrowserVersion}`,
            isProd() ? this.refid3 : `${this.refid2}_${this.refid3}`
        );
    }

    updateLead(values) {
        const hash = localStorage.getItem('hash');

        hash &&
            hash !== 'undefined' &&
            this.sharpstarDataService.addExtraData(hash, values);
    }

    render() {
        const {
            ga,
            gameGA,
            phase,
            adBlocker,
            preroll,
            adProvider,
            gameConfig,
            showAdBlockerModal,
        } = this.state;

        return (
            <>
                {phase === 'landing' && (
                    <Landing
                        ga={ga}
                        gameGA={gameGA}
                        adBlocker={adBlocker}
                        setPhase={this.setPhase}
                        updateState={this.updateState}
                    />
                )}
                {phase === 'push' && !showAdBlockerModal && !!gameGA && (
                    <PushPhase
                        ga={ga}
                        gameGA={gameGA}
                        adBlocker={adBlocker}
                        setPhase={this.setPhase}
                        updateState={this.updateState}
                        createPushLeadHandler={this.createPushLead}
                    />
                )}
                {phase === 'pwa' && !showAdBlockerModal && !!gameGA && (
                    <InstallPhase
                        ga={ga}
                        gameGa={gameGA}
                        adBlocker={adBlocker}
                        setPhase={this.setPhase}
                        updateLead={this.updateLead}
                        updateState={this.updateState}
                    />
                )}
                {phase === 'game' &&
                    !showAdBlockerModal &&
                    !!gameConfig &&
                    !!gameGA && (
                        <GameContainer
                            gaAnalytics={ga}
                            gameAnalytics={gameGA}
                            preroll={preroll}
                            adProvider={adProvider}
                            gameConfig={gameConfig}
                            createPushLead={this.createPushLead}
                            updateLead={this.updateLead}
                        />
                    )}
                {showAdBlockerModal && <AdBlockerModal adBlockOn={adBlocker} />}
            </>
        );
    }
}

export default App;

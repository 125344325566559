import React, { useContext, useEffect, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { consoleInfoLog } from 'App/Helpers/consoleInfoLog';
import hasAdElement from 'App/Helpers/hasAdElement';
import { notify } from 'App/React/_UI/WarningToaster';
import { useConsentScreen } from '../Game/hooks';
import { capitalize } from '../helpers';

const AdServiceContext = React.createContext(null);
export const useAdService = () => useContext(AdServiceContext);

/**
 * Ad service provider
 *
 * @param gameGA - object (required) - initialized Game Analytics instance
 * @param ga - object (required) - initialized Google Analytics instance
 * @param preroll - object (required) { showInterval: number, showStartAttempt: number }
 * @param rewardIds - object (required) { rewardName: rewardId }
 * @param adProvider - adinplay || vli (required) - ad provider name (can be more)
 * @param children - React.Node || React.Element (required)
 */
const AdService = ({
    gameGA,
    ga,
    preroll,
    rewardIds,
    adProvider,
    children,
}) => {
    const [adProvInit, setAdProvInit] = useState(false);
    const [level, setLevel] = useState(null);
    const [prerollCount, setPrerollCount] = useState(0);
    const [rewardType, setRewardType] = useState('');
    const [rewardReceived, setRewardReceived] = useState(false);
    const [vliRewardGranted, setVliRewardGranted] = useState(false);
    const [vliRewardClosed, setVliRewardClosed] = useState(false);
    const [vliRewardEmpty, setVliRewardEmpty] = useState(false);
    const [adinplayRewardFailed, setAdinplayRewardFailed] = useState('');

    useEffect(() => {
        if (adProvider !== 'dev') {
            adProvider === 'adinplay' && initAdinplayAdProvider();
            adProvider === 'vli' && initVliAdProvider();

            gameGA.sendDesignEvent(`Ad:ProviderName:${capitalize(adProvider)}`);
            ga.basicGaEvent('ad', `ad_provider_name_${adProvider}`);
        }
    }, []);

    const consent = useConsentScreen(adProvider, gameGA, ga);

    useEffect(() => {
        if (prerollCount > 0) {
            gameGA.sendAdEvent(
                'RewardReceived',
                'Video',
                adProvider,
                'video_preroll'
            );
            gameGA.sendDesignEvent(
                'AdShown:snakecolorbrake_preroll:Level',
                level
            );
            ga.basicGaEvent('ad', 'preroll_shown', level);
        }
    }, [prerollCount]);

    useEffect(() => {
        if (rewardReceived) {
            gameGA.sendAdEvent(
                'RewardReceived',
                'RewardedVideo',
                adProvider,
                `${capitalize(rewardType)}`
            );
            gameGA.sendDesignEvent(
                `Ad:${capitalize(rewardType)}${capitalize(adProvider)}Granted:Level`,
                level
            );
            ga.basicGaEvent('ad', `${rewardType}_${adProvider}_granted`, level);

            setRewardType('');
        }
    }, [rewardReceived]);

    useEffect(() => {
        if (vliRewardGranted && vliRewardClosed) {
            setRewardReceived(true);
            setVliRewardClosed(false);
            setVliRewardGranted(false);
        }
        if (vliRewardClosed && !vliRewardGranted) {
            gameGA.sendAdEvent(
                'FailedShow',
                'RewardedVideo',
                adProvider,
                `${capitalize(rewardType)}`,
                { errorType: 'RewardClosed' }
            );
            gameGA.sendDesignEvent(
                `Ad:${capitalize(rewardType)}VLIClosed:Level`,
                level
            );
            ga.basicGaEvent('ad', `vli_${rewardType}_closed`, level);

            setRewardType('');
            setVliRewardClosed(false);
        }
    }, [vliRewardClosed, vliRewardGranted]);

    useEffect(() => {
        if (vliRewardEmpty) {
            gameGA.sendAdEvent(
                'FailedShow',
                'RewardedVideo',
                adProvider,
                `${capitalize(rewardType)}`,
                { errorType: 'RewardEmpty' }
            );
            gameGA.sendDesignEvent(
                `Ad:${capitalize(rewardType)}VLIEmpty:Level`,
                level
            );
            ga.basicGaEvent('ad', `vli_${rewardType}_empty`, level);

            setVliRewardEmpty(false);
        }
    }, [vliRewardEmpty]);

    useEffect(() => {
        if (adinplayRewardFailed) {
            gameGA.sendAdEvent(
                'FailedShow',
                'RewardedVideo',
                adProvider,
                `${capitalize(rewardType)}`,
                { errorType: `Reward${capitalize(adinplayRewardFailed)}` }
            );
            gameGA.sendDesignEvent(
                `Ad:${capitalize(rewardType)}Adinplay${capitalize(adinplayRewardFailed)}:Level`,
                level
            );
            ga.basicGaEvent(
                'ad',
                `adinplay_${rewardType}_${adinplayRewardFailed}`,
                level
            );

            setAdinplayRewardFailed('');
            setRewardType('');
        }
    }, [adinplayRewardFailed]);

    /**
     * triggers useEffect by updating PrerollCount to send analytics event after preroll ends
     */
    const triggerPrerollCompleted = () => {
        setPrerollCount((prev) => prev + 1);
    };

    /**
     * shows
     */
    const showWarning = () => {
        notify('There is no ads for you right now, try again later');
    };

    /**
     * initialize Adinplay ad provider
     */
    const initAdinplayAdProvider = () => {
        consoleInfoLog('start Init Adinplay Ad Service', '#D2FBD0', '#094205');
        // Initializes a global aiptag.cmd function
        window.aiptag = window.aiptag || { cmd: [] };
        aiptag.cmd.display = aiptag.cmd.display || [];
        aiptag.cmd.player = aiptag.cmd.player || [];
        // CMP tool settings (gdpr consent modal)
        aiptag.cmp = {
            show: true,
            button: false,
        };
        setAdProvInit(true);
        // Initialize the ad video player
        aiptag.cmd.player.push(function () {
            aiptag.adplayer = new aipPlayer({
                AD_WIDTH: 960,
                AD_HEIGHT: 540,
                AD_DISPLAY: 'fullscreen', //default, fullscreen, center, fill
                LOADING_TEXT: 'loading advertisement',
                PREROLL_ELEM: function () {
                    return document.getElementById(
                        'snakecolorbrake-com_preroll'
                    );
                },
                AIP_COMPLETE: function () {
                    // !!!Please do not remove the PREROLL_ELEM from the page, it will be hidden automatically. If you do want to remove it use the AIP_REMOVE callback.
                    window.focus();
                    triggerPrerollCompleted();
                },
                AIP_REWARDEDGRANTED: function () {
                    window.focus();
                    setRewardReceived(true);
                },
                AIP_REWARDEDCOMPLETE: function (evt) {
                    // evt can be: timeout, empty, unsupported or closed
                    window.focus();

                    if (evt !== 'closed') showWarning();

                    setAdinplayRewardFailed(evt);
                },
            });
        });
    };

    /**
     * setup config, event listeners and initialize vli rewarded ad by placement id
     *
     * @param id - string(required) ad placement id
     */
    const setupVliReward = (id) => {
        vitag.rewardedConfig[id] = vitag.rewardedConfig[id] || {};

        // Trigger the event when receiving a reward here (when conditions for receiving a reward are met)
        vitag.rewardedConfig[id].onRewarded = function () {
            window.focus();
            setVliRewardGranted(true);
        };
        // Trigger the event when the user closes the ad (not ended)
        vitag.rewardedConfig[id].onClose = function () {
            window.focus();
            setVliRewardClosed(true);
        };
        // Trigger the event when there are no ads available
        vitag.rewardedConfig[id].onAdEmpty = function () {
            window.focus();
            showWarning();
            setVliRewardEmpty(true);
        };
        // init reward ad placement
        (vitag.Init = window.vitag.Init || []).push(function () {
            viAPItag.initRewarded(id);
        });
    };

    /**
     * initialize VLI ad provider
     */
    const initVliAdProvider = () => {
        try {
            consoleInfoLog(`start Init VLI Ad Service`, '#D2FBD0', '#094205');
            // init vli ad provider
            window.vitag = window.vitag || {};
            vitag = vitag || {};
            vitag.gdprShowConsentToolButton = false;
            vitag.rewardedConfig = vitag.rewardedConfig || {};
            // setup all rewarded video configs
            Object.values(rewardIds).map((rewardId) =>
                setupVliReward(rewardId)
            );

            setAdProvInit(true);
        } catch (e) {
            gameGA.sendErrorEvent(
                'Error',
                'Failed to initialize VLI ad provider'
            );
            ga.basicGaEvent('error', 'failed_init_vli_ad_provider');
        }
    };

    /**
     * Show Ads preroll or send error event to game analytics
     *
     * @param level - number(required)
     */
    const showPreroll = (level) => {
        setLevel(level);

        if (adProvider === 'adinplay') {
            if (typeof aiptag.adplayer !== 'undefined') {
                aiptag.cmd.player.push(function () {
                    aiptag.adplayer.startPreRoll();

                    gameGA.sendAdEvent(
                        'Show',
                        'Video',
                        adProvider,
                        'video_preroll'
                    );
                    gameGA.sendDesignEvent('Ad:ShowPreroll:Level', level);
                    ga.basicGaEvent('ad', 'show_preroll', level);
                });
            } else {
                gameGA.sendAdEvent(
                    'FailedShow',
                    'Video',
                    adProvider,
                    'video_preroll',
                    { errorType: 'CallFailed' }
                );
                gameGA.sendErrorEvent('Error', 'Failed to show preroll');
                ga.basicGaEvent('error', `preroll_show_failed`, level);
            }
        }
    };

    /**
     * Show small side ad video banner and send event to game analytics
     */
    const showSideVideo = (bannerElement) => {
        if (adProvider === 'vli') {
            const videoId = bannerElement
                .querySelector('div')
                .getAttribute('data-ad-slot');

            (vitag.Init = window.vitag.Init || []).push(function () {
                viAPItag.initPowerInstream(videoId);
            });

            // check if ad container has video ad element after call showSideVideo method and send event to analytics
            setTimeout(() => {
                const gaEventName = bannerElement.getAttribute('data-ga-id');

                if (hasAdElement(bannerElement, 'POWER')) {
                    gameGA.sendAdEvent(
                        'Show',
                        'Video',
                        adProvider,
                        gaEventName
                    );
                    gameGA.sendDesignEvent('Ad:ShowSideVideoVli');
                    ga.basicGaEvent('ad', 'ad_show_side-video_vli');
                } else {
                    gameGA.sendAdEvent(
                        'FailedShow',
                        'Video',
                        adProvider,
                        gaEventName,
                        { errorType: 'CallFailed' }
                    );
                    gameGA.sendErrorEvent(
                        'Warning',
                        `Failed to show ${gaEventName}`
                    );
                    ga.basicGaEvent('error', `fail_${gaEventName}`);
                }
            }, 2500);
        }
    };

    /**
     * Show rewarded ad video or send error event to game analytics
     */
    const showRewardedVideo = (rewardType, level) => {
        setLevel(level);
        setRewardType(rewardType);

        if (adProvider !== 'dev') {
            gameGA.sendAdEvent(
                'Show',
                'RewardedVideo',
                adProvider,
                `${capitalize(rewardType)}`
            );
            gameGA.sendDesignEvent(
                `Ad:CallReward${capitalize(adProvider)}:${capitalize(rewardType)}:Level`,
                level
            );
            ga.basicGaEvent('ad', `call_${rewardType}_${adProvider}`, level);

            if (adProvider === 'adinplay') {
                if (typeof aiptag.adplayer !== 'undefined') {
                    aiptag.cmd.player.push(function () {
                        aiptag.adplayer.startRewardedAd({
                            subid: rewardType,
                            preload: false,
                            showLoading: true,
                        });
                    });
                } else {
                    gameGA.sendAdEvent(
                        'FailedShow',
                        'RewardedVideo',
                        adProvider,
                        `${capitalize(rewardType)}`,
                        { errorType: 'CallFailed' }
                    );
                    gameGA.sendErrorEvent(
                        'Error',
                        `Failed to show Adinplay Reward video on level: ${level}`
                    );
                    ga.basicGaEvent(
                        'error',
                        `adinplay_${capitalize(rewardType)}_failed`,
                        level
                    );
                }
            }
            if (adProvider === 'vli') {
                (vitag.Init = window.vitag.Init || []).push(function () {
                    viAPItag.getRewardedAd(rewardIds[rewardType]);
                });
            }
        } else {
            // for dev mode - always receive reward
            setTimeout(() => {
                setRewardReceived(true);
            }, 100);
        }
    };

    /**
     * Set banner visibility
     *
     * @param refElement - <HTMLElement>(required)
     * @param visible - <HTMLElement>(boolean)
     */
    const setBannerVisible = (refElement, visible) => {
        visible
            ? refElement.classList.add('visible')
            : refElement.classList.remove('visible');
    };

    /**
     * Displays and update ad banner content
     *
     * @param bannerElement - <HTMLElement>(required)
     * @param level - number(required)
     */
    const updateAd = (bannerElement, level) => {
        if (adProvider !== 'dev') {
            if (!bannerElement) return;

            const banner = bannerElement.querySelector('div');

            if (adProvider === 'adinplay') {
                aiptag.cmd.display.push(function () {
                    aipDisplayTag.display(banner.id);
                });
            }
            if (adProvider === 'vli') {
                (vitag.Init = window.vitag.Init || []).push(function () {
                    viAPItag.display(banner.getAttribute('data-ad-slot'));
                });
            }

            // check if ad container has ad element after call adUpdate method and send event to analytics
            setTimeout(() => {
                const gaEventName = bannerElement.getAttribute('data-ga-id');
                const hasAdIframe = hasAdElement(bannerElement, 'IFRAME');

                if (hasAdIframe) {
                    gameGA.sendAdEvent(
                        'Show',
                        'Banner',
                        adProvider,
                        gaEventName
                    );
                    gameGA.sendDesignEvent(
                        `Ad:${gaEventName}${level ? ':Level' : ''}`,
                        level && level
                    );
                    ga.basicGaEvent('ad', gaEventName, level && level);
                } else {
                    gameGA.sendAdEvent(
                        'FailedShow',
                        'Banner',
                        adProvider,
                        gaEventName,
                        { errorType: 'CallFailed' }
                    );
                    gameGA.sendErrorEvent(
                        'Warning',
                        `${gaEventName} update failed${level ? ` on level: ${level}` : ''}`
                    );
                    ga.basicGaEvent(
                        'error',
                        `fail_${gaEventName}`,
                        level && level
                    );
                }
            }, 2500);
        }
    };

    const methods = {
        updateAd,
        showPreroll,
        showRewardedVideo,
        setBannerVisible,
        showSideVideo,
        adProvInit,
        rewardReceived,
        setRewardReceived,
        consent,
        prerollCount,
        preroll,
    };

    return (
        <>
            <AdServiceContext.Provider value={methods}>
                {children}
            </AdServiceContext.Provider>
            <Toaster
                containerStyle={{ top: 200 }}
                gutter={-145}
                toastOptions={{
                    duration: 2000,
                    position: 'top-center',
                    style: {
                        background: '#FFC213',
                        color: '#ffffff',
                    },
                }}
            />
        </>
    );
};

export default AdService;

import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import SpinnerDot from '@/React/_UI/SpinnerDot/SpinnerDot';
import './modal.scss';
import polyglot from '../../../../Pages/games/aq/web/football/localization';

const modalRoot = document.getElementById('modal-root');

class Modal extends React.Component {
    constructor(props) {
        super(props);
        this.el = document.createElement('div');
        this.el.classList.add('modal-con');
    }

    componentDidMount() {
        modalRoot.appendChild(this.el);
    }

    componentWillUnmount() {
        modalRoot.removeChild(this.el);
    }

    render() {
        const { title, content, onClose, isAntiClose, lang } = this.props;

        return ReactDOM.createPortal(
            <div
                className='modal-dialog modal-dialog-scrollable'
                role='document'>
                <div className='modal-content'>
                    <div className='modal-header'>
                        <h5 className='modal-title' id='ModalScrollableTitle'>
                            {title}
                        </h5>
                        {!isAntiClose && (
                            <button
                                type='button'
                                className='close'
                                onClick={onClose}
                                aria-label='Close'>
                                <span aria-hidden='true'>&times;</span>
                            </button>
                        )}
                    </div>
                    <div
                        className={`modal-body ${this.props.noTitle ? 'no-title' : ''} ${this.props.noImg ? 'no-img' : ''}`}>
                        {content !== '' && (
                            <div
                                dangerouslySetInnerHTML={{ __html: content }}
                            />
                        )}
                        {content === '' && <SpinnerDot color={'#ccc'} />}
                    </div>
                    {!isAntiClose && (
                        <div className='modal-footer'>
                            <button
                                type='button'
                                className='modal-btn'
                                onClick={onClose}>
                                {lang
                                    ? polyglot.t(
                                          `${lang}.modals_slug.buttons-close`
                                      )
                                    : 'Close'}
                            </button>
                        </div>
                    )}
                </div>
            </div>,
            this.el
        );
    }
}

export default Modal;

Modal.propTypes = {
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
};
